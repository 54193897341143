import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Drop from "./DropCategories";
import DropSocial from "./DropSocial";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

function Navigation() {
  const [nav, setNav] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setNav(event.target.value);
  };
  const submitHandler = () => {
    if (nav.length >= 1 && nav !== ".") {
      navigate(`/navsearch/${nav}`);
    }
  };

  return (
    <Navbar expand='sm' variant='dark'>
      <Container>
        <Navbar.Toggle
          aria-controls='basic-navbar-nav'
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto fw-bold'>
            <Nav.Link
              target=''
              href='/'
              style={{
                color: "white",
              }}
            >
              Home / Search
            </Nav.Link>
            <Drop />
            {/* <Nav.Link
              target=""
              href="/search"
              style={{
                color: "white",
              }}
            >
              Filtered Search
            </Nav.Link> */}
            <Nav.Link
              target=''
              href='/alphabetical'
              style={{
                color: "white",
              }}
            >
              Search By First Letter
            </Nav.Link>
            <Nav.Link
              target=''
              href='/quick'
              style={{
                color: "white",
              }}
            >
              Quick Search
            </Nav.Link>
            <DropSocial />
          </Nav>
        </Navbar.Collapse>
        <form
          onSubmit={(event) => {
            event.preventDefault();
          }}
          id='search-form'
        >
          <div className='input-group mb-1 p-2'>
            <input
              onChange={handleInputChange}
              className='form-control'
              name='searchDrinks'
              id='searchDrinks'
              type='search'
              placeholder='Search drinks'
            />
            <Button type='submit' variant='dark' onClick={submitHandler}>
              Submit
            </Button>
          </div>
        </form>
      </Container>
    </Navbar>
  );
}

export default Navigation;
