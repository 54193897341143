import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { Link } from "react-router-dom";

function Alphabet() {
  const letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <Container
      fluid="md"
      className="mt-3 fade-in"
      // style={{
      //   backgroundColor: "rgba(0,0,0,0.7)",
      //   textShadow: "#FC0 1px 0 10px",
      //   borderRadius: "20px",
      // }}
    >
      <div className="mt-4">
        <center>
          <h1 className="neon">Mini BarBuddy</h1>
        </center>
        <div
          className="p-1"
          style={{
            textAlign: "center",
            textShadow: "#FC0 1px 0 10px",
          }}
        >
          <h1 className="text-white text-decoration-underline">
            Search By First Letter:
          </h1>
        </div>
        <Container
          fluid="md"
          style={{
            backgroundColor: "rgba(0,0,0,0.7)",
            textShadow: "#FC0 1px 0 10px",
            borderRadius: "30px",
          }}
        >
          <Row xs="auto" className="mt-4">
            {letters.map((alphaChar) => {
              return (
                <Col xs={3} className="mt-4" key={alphaChar}>
                  <div align="center" className="mb-4">
                    <Link
                      className="text-white no-underline"
                      to={`/alphabetical/${alphaChar}`}
                    >
                      <h2 className="text-white">{alphaChar}</h2>
                    </Link>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </Container>
  );
}

export default Alphabet;
