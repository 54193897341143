import React, { useState, useEffect } from "react";
import { Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Shaker from "./Shaker";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

function QuickSearch() {
  const [quick, setQuick] = useState("");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const ApiData = async () => {
      setIsLoading(true);
      if (quick.length > 0) {
        const response = await fetch(
          `https://www.thecocktaildb.com/api/json/v2/9973533/filter.php?i=${quick}`
        );
        const data = await response.json();
        setResults(data.drinks);
      }
      setIsLoading(false);
    };
    ApiData();
  }, [quick]);

  const handleRadio = (event) => {
    setQuick(event.target.value);
  };

  return (
    <Container fluid>
      <Container fluid>
        <h1 className="neon" align="center">
          Mini BarBuddy
        </h1>
        <Form>
          <div className="text-white fw-bold fs-4" align="center">
            <Form.Check
              onChange={handleRadio}
              checked={quick === "Vodka"}
              type="radio"
              name="radioGroup"
              value="Vodka"
              label="Vodka"
              inline
            />
            <Form.Check
              onChange={handleRadio}
              checked={quick === "Gin"}
              type="radio"
              name="radioGroup"
              value="Gin"
              label="Gin"
              inline
            />
            <Form.Check
              onChange={handleRadio}
              checked={quick === "Rum"}
              type="radio"
              name="radioGroup"
              value="Rum"
              label="Rum"
              inline
            />
            <Form.Check
              onChange={handleRadio}
              checked={quick === "Tequila"}
              type="radio"
              name="radioGroup"
              value="Tequila"
              label="Tequila"
              inline
            />
            <Form.Check
              onChange={handleRadio}
              checked={quick === "Scotch"}
              type="radio"
              name="radioGroup"
              value="Scotch"
              label="Scotch"
              inline
            />
          </div>
        </Form>
        <div className="mb-1 p-3 text-white" align="left">
          <b>results: {results?.length}</b>
        </div>
      </Container>
      <Container
        fluid="md"
        className="justify-content-md-center overflow-scroll"
      >
        {isLoading ? (
          <Shaker />
        ) : (
          <Row className="justify-content-md-center">
            {results.map((row) => {
              return (
                <Col
                  key={row.idDrink}
                  sm={4}
                  className="justify-content-md-center"
                >
                  <div align="center">
                    <Link
                      className="link-dark no-underline"
                      to={`/detail/${row.idDrink}`}
                    >
                      <Card
                        className="mb-3"
                        style={{
                          maxWidth: "20rem",
                          backgroundColor: "#FBFAF5",
                        }}
                      >
                        <Card.Header className="header-title">
                          {row.strDrink}
                        </Card.Header>
                        <Card.Body>
                          <Card.Img variant="top" src={row.strDrinkThumb} />
                        </Card.Body>
                      </Card>
                    </Link>
                  </div>
                </Col>
              );
            })}
          </Row>
        )}
      </Container>
    </Container>
  );
}

export default QuickSearch;
