import Dropdown from "react-bootstrap/Dropdown";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";

function Drop() {
  return (
    <Dropdown as={NavItem}>
      <Dropdown.Toggle className="text-white" as={NavLink}>
        Categories
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="/category/Beer">Beer</Dropdown.Item>
        <Dropdown.Item href="/category/Cocoa">Cocoa</Dropdown.Item>
        <Dropdown.Item href="/category/Coffee %2f Tea">
          Coffee / Tea
        </Dropdown.Item>
        <Dropdown.Item href="/category/Cocktail">Cocktail</Dropdown.Item>
        <Dropdown.Item href="/category/Homemade Liqueur">
          Homemade Liqueur
        </Dropdown.Item>
        <Dropdown.Item href="/category/Non_Alcoholic">
          Non Alcoholic
        </Dropdown.Item>
        <Dropdown.Item href="/category/Ordinary Drink">
          Ordinary Drink
        </Dropdown.Item>
        <Dropdown.Item href="/category/Other %2f Unknown">
          Other / Unknown
        </Dropdown.Item>
        <Dropdown.Item href="/category/Punch %2f Party Drink">
          Punch / Party Drink
        </Dropdown.Item>
        <Dropdown.Item href="/category/Shake">Shake</Dropdown.Item>
        <Dropdown.Item href="/category/Shot">Shot</Dropdown.Item>
        <Dropdown.Item href="/category/Soft Drink">Soft Drink</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Drop;
