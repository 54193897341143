import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Alphabet from "./components/Alphabetical";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Categories from "./components/Categories";
import DrinkCards from "./components/DrinkCards";
import DrinkDetail from "./components/DrinkDetail";
import HomePage from "./components/HomePage";
// import FilteredDrinks from "./components/Filtered";
import FirstLetter from "./components/FirstLetter";
import Navigation from "./components/NavBar";
import NavSearch from "./components/navSearch";
import QuickSearch from "./components/QuickSearch";
import Unknown from "./components/Unknown";
function App() {
  return (
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route path='/' element={<DrinkCards />} />
        <Route path='/alphabetical' element={<Alphabet />} />
        <Route path='/alphabetical/:letter' element={<FirstLetter />} />
        <Route path='/category/:name' element={<Categories />} />
        <Route path='/detail/:id' element={<DrinkDetail />} />
        {/* <Route path="/search" element={<FilteredDrinks />} /> */}
        <Route path='/recipes' element={<HomePage />} />
        <Route path='/navsearch/:nav' element={<NavSearch />} />
        <Route path='/quick' element={<QuickSearch />} />
        <Route path='*' element={<Unknown />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
