import React, { useState, useEffect } from "react";
import {
  FacebookShareButton,
  EmailShareButton,
  PinterestShareButton,
} from "react-share";

function ShareSocial() {
  const [pageUrl, setPageUrl] = useState("");
  const description = "Cocktail recipes. Search share and enjoy!";

  useEffect(() => {
    setPageUrl(window.location.href);
  }, []);

  const ShareViaSMS = () => {
    const message = `Check out this DRAAANK: ${pageUrl}`;
    const encodedMessage = encodeURI(message);
    const smsUrl = `sms:?&body=${encodedMessage}`;
    window.open(smsUrl);
  };

  return (
    <div>
      <img onClick={ShareViaSMS} className="p-3" src="/text.png" alt="text" />
      <EmailShareButton
        url={pageUrl}
        subject="Mini Barbuddy"
        body={description}
        separator="   "
      >
        <img className="p-2" src="/email.png" alt="email" />
      </EmailShareButton>
      <FacebookShareButton url={pageUrl} className="p-2" quote={description}>
        <img src="/facebook.png" alt="facebook" />
      </FacebookShareButton>
      <PinterestShareButton
        url={pageUrl}
        className="p-2"
        media="https://images.pexels.com/photos/110472/pexels-photo-110472.jpeg"
        description={description}
      >
        <img className="p-2" src="/pinterest.png" alt="pinterest" />
      </PinterestShareButton>
    </div>
  );
}
export default ShareSocial;
