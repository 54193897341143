import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";

function Unknown() {
  return (
    <Container fluid className="unknown">
      <div>
        <p className="text-white fs-1 fw-bold">
          Sorry we couldn't find that page....
        </p>
      </div>
      <div className="mt-4">
        <Link to="/">
          <Button variant="dark">Home</Button>
        </Link>
      </div>
    </Container>
  );
}
export default Unknown;
