import App from "./App";
import { inject } from "@vercel/analytics";
// import { Helmet } from "react-helmet";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

inject();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div>
    {/* <Helmet>
      <meta
        name="description"
        content="Find delicious and easy-to-follow recipes for all your favorite drinks, including cocktails, mocktails, smoothies, and more. Browse our extensive collection of recipes, filter by ingredient or type of drink and share the recipes with friends. Whether you're a seasoned mixologist or just starting out, our app has something for everyone."
      />
    </Helmet> */}
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
