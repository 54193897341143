import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/Col";
import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Shaker from "./Shaker";

function FirstLetter() {
  const [ByLetter, setByLetter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { letter } = useParams();

  useEffect(() => {
    const fetchDrink = async () => {
      setIsLoading(true);
      const url = `https://www.thecocktaildb.com/api/json/v2/9973533/search.php?f=${letter}`;
      const result = await fetch(url);
      const data = await result.json();
      setByLetter(data.drinks);
      setIsLoading(false);
    };
    fetchDrink();
  }, [letter]);

  if (ByLetter !== null) {
    return (
      <Container fluid="md" className="mt-3 fade-in">
        <div className="mt-4">
          <center>
            <h1 className="neon">Mini BarBuddy</h1>
          </center>
          <div className="mb-1 p-3 text-white" align="left">
            <b>results: {ByLetter?.length}</b>
          </div>
          <Container
            fluid="md"
            className="justify-content-md-center overflow-scroll"
          >
            {isLoading ? (
              <Shaker />
            ) : (
              <Row className="justify-content-md-center">
                {ByLetter.map((row) => {
                  return (
                    <Col
                      key={row.idDrink}
                      sm={4}
                      className="justify-content-md-center"
                    >
                      <Link
                        className="link-dark no-underline"
                        to={`/detail/${row.idDrink}`}
                      >
                        <div align="center">
                          <Card
                            className="mb-3"
                            style={{
                              maxWidth: "20rem",
                              backgroundColor: "#FBFAF5",
                            }}
                          >
                            <Card.Header className="header-title">
                              {row.strDrink}
                            </Card.Header>
                            <Card.Body>
                              <Card.Img variant="top" src={row.strDrinkThumb} />
                            </Card.Body>
                          </Card>
                        </div>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Container>
        </div>
      </Container>
    );
  } else {
    return (
      <Container
        fluid="md"
        className="mt-3 fade-in"
        style={{
          backgroundColor: "rgba(0,0,0,0.7)",
          textShadow: "#FC0 1px 0 10px",
          borderRadius: "20px",
        }}
      >
        <div className="mt-4">
          <center>
            <h1 className="neon">Mini BarBuddy</h1>
          </center>
          <b className="text-white fw-bold fs-5">
            No results for the letter {letter} ....
          </b>
          <div align="left">
            <Link className="link-dark no-underline" to={"/alphabetical"}>
              <Button className="btn btn-secondary">Try another</Button>
            </Link>
          </div>
        </div>
      </Container>
    );
  }
}

export default FirstLetter;
