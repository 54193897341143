import Container from "react-bootstrap/esm/Container";
import { CookieConsent } from "react-cookie-consent";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Shaker from "./Shaker";

function DrinkCards() {
  const [results, setResults] = useState([]);
  const [random, setRandom] = useState([]);
  const [variety, setVariety] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const ApiData = async () => {
      setIsLoading(true);

      const response1 = await fetch(
        "https://www.thecocktaildb.com/api/json/v2/9973533/popular.php"
      );
      const data2 = await response1.json();
      setResults(data2.drinks);

      const randomDrink = await fetch(
        "https://www.thecocktaildb.com/api/json/v2/9973533/random.php"
      );
      const data1 = await randomDrink.json();
      setRandom(data1.drinks);

      const moreRandom = await fetch(
        "https://www.thecocktaildb.com/api/json/v2/9973533/randomselection.php"
      );
      const data3 = await moreRandom.json();
      setVariety(data3.drinks);

      setIsLoading(false);
    };
    ApiData();
  }, []);

  const varietyHandler = () => {
    setResults(variety);
  };

  if (results) {
    return (
      <Container fluid='md' className='mt-3 fade-in'>
        <div className='mt-4'>
          <center>
            <h1 className='neon'>Mini BarBuddy</h1>
          </center>
          <center>
            <Link
              className='link-dark no-underline'
              to={`/detail/${random[0]?.idDrink}`}
            >
              <Button className='btn btn-secondary text-black bg-light'>
                Random Drink
              </Button>
            </Link>
            <Button onClick={varietyHandler} variant='dark'>
              10 Random Drinks
            </Button>
          </center>
          <div className='mb-2 p-2 text-white' align='left'>
            <b>results: {results?.length}</b>
          </div>
          <Container
            fluid='md'
            className='justify-content-md-center overflow-scroll'
          >
            {isLoading ? (
              <Shaker />
            ) : (
              <Row className='justify-content-md-center'>
                {results.map((row) => {
                  return (
                    <Col
                      key={row.idDrink}
                      sm={4}
                      className='justify-content-md-center'
                      id='column-card'
                    >
                      <div align='center'>
                        <Card
                          className='mb-3'
                          style={{
                            maxWidth: "20rem",
                            backgroundColor: "#FBFAF5",
                          }}
                        >
                          <Link
                            className='link-dark no-underline'
                            to={`/detail/${row.idDrink}`}
                          >
                            <Card.Header className='header-title'>
                              {row.strDrink}
                            </Card.Header>
                            <Card.Body>
                              <Card.Img variant='top' src={row.strDrinkThumb} />
                            </Card.Body>
                          </Link>
                        </Card>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Container>
        </div>
        <div>
          <CookieConsent
            location='bottom'
            buttonText='I understand'
            cookieName='myCookieName'
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            SameSite='Secure'
          >
            {/* This website uses cookies to enhance the user experience. By
            continuing to use this website, you consent to the use of cookies. */}
            Welcome to our website! We want to let you know that while our site
            is accessible from all devices, for a better user experience please
            access from a mobile device
          </CookieConsent>
        </div>
      </Container>
    );
  } else {
    return (
      <Container fluid='md'>
        <div className='mt-4'>
          <center>
            <h1 className='neon'>Mini BarBuddy</h1>
          </center>
          <div className='text-white fw-bold fs-5'>
            Please refine your search....
          </div>
        </div>
      </Container>
    );
  }
}

export default DrinkCards;
