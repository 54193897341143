import Container from "react-bootstrap/esm/Container";
import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

function HomePage() {
  const [results, setResults] = useState([]);
  const [search, setSearch] = useState("");
  const [submit, setSubmit] = useState("");

  useEffect(() => {
    const ApiData = async () => {
      const randomDrink = [
        "apple",
        "orange",
        "green",
        "pink",
        "blue",
        "punch",
        "whis",
      ];
      if (submit.length > 0) {
        const response = await fetch(
          `https://www.thecocktaildb.com/api/json/v2/9973533/search.php?s=${submit}`
        );
        const data = await response.json();
        setResults(data.drinks);
      } else {
        setSubmit(randomDrink[Math.floor(Math.random() * randomDrink.length)]);
      }
    };
    ApiData();
  }, [submit]);

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const submitHandler = () => {
    setSubmit(search);
  };

  if (results) {
    return (
      <Container>
        <div>
          <center>
            {/* <img alt="" src="/c&d.jpeg" /> */}
            <h1 className="neon">Mini BarBuddy</h1>
          </center>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              submitHandler();
            }}
            id="search-form"
          >
            <div className="input-group mb-1 p-4">
              <input
                onChange={handleInputChange}
                className="form-control"
                name="searchDrinks"
                id="searchDrinks"
                type="search"
                placeholder="Search drinks"
              />
              <Button onClick={submitHandler}>Submit</Button>
            </div>
          </form>
          <div>results: {results.length}</div>
          <Table
            striped
            bordered
            hover
            variant="light"
            className="table table-bordered table-striped mt-2"
          >
            <thead>
              <tr>
                <th className="text-center col col-lg-2">Drink Name</th>
                <th className="text-center col col-lg-2">Drink Image</th>
                <th className="text-center col col-lg-2">Instructions</th>
                <th className="text-center col col-lg-2">Ingredients</th>
                <th className="text-center col col-lg-2">Video</th>
              </tr>
            </thead>
            <tbody>
              {results.map((row) => {
                return (
                  <tr key={row.idDrink}>
                    <td className="text-center col col-lg-2">{row.strDrink}</td>
                    <td className="text-center col col-lg-2">
                      <img
                        className="p-1"
                        src={row.strDrinkThumb}
                        alt={row.strDrink}
                        width="170px"
                      />
                    </td>
                    <td className="text-center col col-lg-2">
                      {row.strInstructions}
                    </td>
                    <td>
                      Ingredients: <br></br>
                      {row.strMeasure1} {row.strIngredient1} <br></br>
                      {row.strMeasure2} {row.strIngredient2} <br></br>
                      {row.strMeasure3} {row.strIngredient3} <br></br>
                      {row.strMeasure4} {row.strIngredient4} <br></br>
                      {row.strMeasure5} {row.strIngredient5} <br></br>
                      {row.strMeasure6} {row.strIngredient6} <br></br>
                      {row.strMeasure7} {row.strIngredient7} <br></br>
                      {row.strMeasure8} {row.strIngredient8} <br></br>
                      {row.strMeasure9} {row.strIngredient9} <br></br>
                      {row.strMeasure10} {row.strIngredient10} <br></br>
                      {row.strMeasure11} {row.strIngredient11} <br></br>
                      {row.strMeasure12} {row.strIngredient12} <br></br>
                      {row.strMeasure13} {row.strIngredient13} <br></br>
                      {row.strMeasure14} {row.strIngredient14} <br></br>
                      {row.strMeasure15} {row.strIngredient15} <br></br>
                    </td>
                    {row.strVideo ? (
                      <td className="text-center col col-lg-2">
                        <iframe
                          title={row.strVideo}
                          width="420"
                          height="315"
                          src={row.strVideo.replace("watch?v=", "embed/")}
                          // https://www.youtube.com/watch?v=TFWPtkNoF4Y
                          // needs to be https://www.youtube.com/embed/TFWPtkNoF4Y
                        ></iframe>
                      </td>
                    ) : (
                      <td className="text-center col col-lg-2">
                        No video available...
                        <br /> Coming soon!
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
    );
  } else {
    return (
      <Container>
        <div className="mt-4">
          <center>
            <h1 className="neon">Mini BarBuddy</h1>
          </center>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              submitHandler();
            }}
            id="search-form"
          >
            <div className="input-group mb-3 p-4">
              <input
                onChange={handleInputChange}
                className="form-control"
                name="searchDrinks"
                id="searchDrinks"
                type="search"
                placeholder="Search drinks"
              />
              <Button onClick={submitHandler}>Submit</Button>
            </div>
          </form>
          <div>results: 0 Please refine your search...</div>
        </div>
      </Container>
    );
  }
}

export default HomePage;
