import { Container, Card, Image, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Converter from "./Converter";
import Shaker from "./Shaker";

const DrinkDetail = () => {
  const [Drink, setDrink] = useState({});
  const [pageUrl, setPageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const fetchDrink = async () => {
      setIsLoading(true);
      const url = `https://www.thecocktaildb.com/api/json/v2/9973533/lookup.php?i=${id}`;
      const result = await fetch(url);
      const data = await result.json();
      setDrink(data.drinks[0]);
      setPageUrl(window.location.href);
      setIsLoading(false);
    };
    fetchDrink();
  }, [id]);

  // trying to share page via sms

  const handleShareViaSMS = () => {
    const message = `Check out this DRAAANK: ${pageUrl}`;
    const encodedMessage = encodeURI(message);
    const smsUrl = `sms:?&body=${encodedMessage}`;
    window.open(smsUrl);
  };

  // assembled ingredients and measurements without null value

  const ingredients = {};

  for (let i in Drink) {
    if (i.includes("strIngred") && Drink[i] !== null) {
      ingredients[i] = Drink[i];
    } else if (i.includes("strMeasu") && Drink[i] !== null) {
      ingredients[i] = Drink[i];
    }
  }

  // need boolean to check if measurements contain cL or dL

  let needsConvert = { needed: false };

  for (const [key, value] of Object.entries(ingredients)) {
    if (
      (key.includes("strM") && value.toLowerCase().includes("cl")) ||
      (key.includes("strM") && value.toLowerCase().includes("dl")) ||
      (key.includes("strM") && value.toLowerCase().includes("ml"))
    ) {
      needsConvert["needed"] = true;
    }
  }

  return (
    <div>
      <Container fluid="md" className="mt-3 fade-in">
        <div className="mt-4">
          <center>
            <h1 className="neon">Mini BarBuddy</h1>
          </center>
        </div>
        {isLoading ? (
          <Shaker />
        ) : (
          <center className="mt-3 scroll-container">
            <Card
              style={{
                backgroundColor: "#FBFAF5",
              }}
            >
              <Image
                src="/sms.png"
                style={{ width: "25px" }}
                onClick={handleShareViaSMS}
                className="ms-auto"
              />
              <Col>
                <h3 className="header-title mb-4">{Drink.strDrink}</h3>
                <Image src={Drink.strDrinkThumb} style={{ maxWidth: "90%" }} />
                {Drink.strVideo ? (
                  <div>
                    <iframe
                      className="mt-3"
                      title={Drink.strVideo}
                      width="90%"
                      height="280"
                      src={Drink.strVideo.replace("watch?v=", "embed/")}
                    ></iframe>
                  </div>
                ) : (
                  <p className="mt-2">
                    No video available...
                    <br /> Coming soon!
                  </p>
                )}
              </Col>
              <Col className="mt-3">
                <Container style={{ maxWidth: "80%" }}>
                  <h4>Instructions:</h4>
                  <p>{Drink.strInstructions}</p>
                </Container>

                <h4>Serve in:</h4>
                <p>{Drink.strGlass}</p>
                {needsConvert["needed"] ? <Converter /> : <div></div>}
                <div>
                  <br></br>
                  <Container>
                    <h5>Ingredients:</h5>
                    <p>
                      {Drink?.strMeasure1} {Drink?.strIngredient1} <br></br>
                      {Drink?.strMeasure2} {Drink?.strIngredient2} <br></br>
                      {Drink?.strMeasure3} {Drink?.strIngredient3} <br></br>
                      {Drink?.strMeasure4} {Drink?.strIngredient4} <br></br>
                      {Drink?.strMeasure5} {Drink?.strIngredient5} <br></br>
                      {Drink?.strMeasure6} {Drink?.strIngredient6} <br></br>
                      {Drink?.strMeasure7} {Drink?.strIngredient7} <br></br>
                      {Drink?.strMeasure8} {Drink?.strIngredient8} <br></br>
                      {Drink?.strMeasure9} {Drink?.strIngredient9} <br></br>
                      {Drink?.strMeasure10} {Drink?.strIngredient10} <br></br>
                      {Drink?.strMeasure11} {Drink?.strIngredient11} <br></br>
                      {Drink?.strMeasure12} {Drink?.strIngredient12} <br></br>
                      {Drink?.strMeasure13} {Drink?.strIngredient13} <br></br>
                      {Drink?.strMeasure14} {Drink?.strIngredient14} <br></br>
                      {Drink?.strMeasure15} {Drink?.strIngredient15}
                    </p>
                  </Container>
                </div>
              </Col>
            </Card>
          </center>
        )}
      </Container>
    </div>
  );
};

export default DrinkDetail;
