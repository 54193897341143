function Shaker() {
  return (
    <center>
      <img
        src="/shaker.png"
        alt=""
        className="shaker"
        style={{ width: "5rem" }}
      />
      <h4 className="text-white">Shake while you wait...</h4>
    </center>
  );
}

export default Shaker;
