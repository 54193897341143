import Container from "react-bootstrap/esm/Container";
import React, { useState } from "react";
import Card from "react-bootstrap/Card";

function Converter() {
  const [amount, setAmount] = useState("");
  const [type, setType] = useState("");

  const measurements = [
    { type: "cl", amount: 2.957 },
    { type: "dl", amount: 3.381 },
    { type: "ml", amount: 29.574 },
  ];

  const handleFirstInputChange = (event) => {
    setAmount(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  let converted = 0;

  if (amount > 0) {
    converted = amount / type;
  }

  return (
    <Container
      fluid="md"
      style={{
        width: "70vw",
      }}
    >
      <div className="mt-4">
        <center>
          <Card
            style={{
              backgroundColor: "#FBFAF5",
            }}
          >
            <h1>Convert to oz</h1>
            <form
              onSubmit={(event) => {
                event.preventDefault();
              }}
              id="convert-form"
            >
              <div className="input-group mb-1 p-4">
                <input
                  onChange={handleFirstInputChange}
                  className="form-control"
                  name="convert"
                  id="convert"
                  type="converter"
                  placeholder="Amount"
                />
                <select
                  onChange={handleTypeChange}
                  name="type"
                  id="type"
                  className="form-select"
                >
                  <option>Choose a measurement</option>
                  {measurements.map((measurement) => {
                    return (
                      <option key={measurement.type} value={measurement.amount}>
                        {measurement.type}
                      </option>
                    );
                  })}
                </select>
              </div>
              {amount ? (
                <h4>{converted.toFixed(2)} oz</h4>
              ) : (
                <h4>Please fill out form</h4>
              )}
            </form>
          </Card>
        </center>
      </div>
    </Container>
  );
}

export default Converter;
