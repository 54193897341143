import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/Col";
import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Shaker from "./Shaker";

function Categories() {
  const [categoryDrinks, setCategoryDrinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { name } = useParams();

  useEffect(() => {
    const fetchDrink = async () => {
      setIsLoading(true);
      if (name !== "Non_Alcoholic") {
        const url = `https://www.thecocktaildb.com/api/json/v2/9973533/filter.php?c=${name}`;
        const result = await fetch(url);
        const data = await result.json();
        setCategoryDrinks(data.drinks);
      } else {
        const url2 = `https://www.thecocktaildb.com/api/json/v2/9973533/filter.php?a=${name}`;
        const result2 = await fetch(url2);
        const data2 = await result2.json();
        setCategoryDrinks(data2.drinks);
      }
      setIsLoading(false);
    };
    fetchDrink();
  }, [name]);

  return (
    <Container
      fluid="md"
      className="mt-3 fade-in"
      // style={{
      //   backgroundColor: "rgba(0,0,0,0.7)",
      //   textShadow: "#FC0 1px 0 10px",
      //   borderRadius: "20px",
      // }}
    >
      <div className="mt-4">
        <center>
          <h1 className="neon">Mini BarBuddy</h1>
        </center>
        <div className="mb-1 p-3 text-white" align="left">
          <b>results: {categoryDrinks?.length}</b>
        </div>
        <Container
          fluid="md"
          className="justify-content-md-center overflow-scroll"
        >
          {isLoading ? (
            <Shaker />
          ) : (
            <Row className="justify-content-md-center">
              {categoryDrinks.map((row) => {
                return (
                  <Col
                    key={row.idDrink}
                    sm={4}
                    className="justify-content-md-center"
                  >
                    <Link
                      className="link-dark no-underline"
                      to={`/detail/${row.idDrink}`}
                    >
                      <div align="center">
                        <Card
                          className="mb-3"
                          style={{
                            maxWidth: "20rem",
                            backgroundColor: "#FBFAF5",
                          }}
                        >
                          <Card.Header className="header-title">
                            {row.strDrink}
                          </Card.Header>
                          <Card.Body>
                            <Card.Img variant="top" src={row.strDrinkThumb} />
                          </Card.Body>
                        </Card>
                      </div>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default Categories;
