import Dropdown from "react-bootstrap/Dropdown";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";
import ShareSocial from "./Social";

function DropSocial() {
  return (
    <Dropdown as={NavItem}>
      <Dropdown.Toggle className="text-white" as={NavLink}>
        Share with friends!
      </Dropdown.Toggle>
      <Dropdown.Menu className="bg-transparent">
        <Dropdown.Item>
          <ShareSocial />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
export default DropSocial;
